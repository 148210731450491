import React from 'react';
import { graphql } from 'gatsby';
import { ItemsListIndexPage, PageWithSubNav } from '../../components';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav pageType="design" title="Words" subnav="words">
      <ItemsListIndexPage data={data} listType="word" listLayout="2" />
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query wordsIndexQuery($tierOne: String = "Words") {
    ...indexList
  }
`;
